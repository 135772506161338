import { request } from '@/utils/http'
import { questionsBankRequest } from '@/utils/http/questionsBank-service'
import store from '@/store'
import config from '@/config'
// 获取官方知识点
export const queryOfficialKnowledgePoint = (params: any) => request('GET', '/official/knowledge-points', params)

// 获取教师知识点
export const queryTeacherKnowledgePoint = (params: any) => request('GET', '/teacher/knowledge-points', params)

// 获取官方知识点
export const getOfficialKnowledgePoint = (id: any) => request('GET', `/official/knowledge-point/${id}`)

// 教师知识点
export const getTeacherKnowledgePoint = (id: any) => request('GET', `/teacher/knowledge-point/${id}`)

// 官方tag获取
export const getOfficialTag = (params: any) => request('GET', '/official/tags', params)

// 教师tag获取
export const getTeacherTags = (params: any) => request('GET', '/teacher/tags', params)

// 获取官方谱例歌曲
export const getOfficialSheetSong = (params: any) => request('GET', '/official/sheet-songs', params)

// 获取教师谱例歌曲
export const getTeacherSheetSong = (params: any) => request('GET', '/teacher/sheet-songs', params)

// 获取教师欣赏歌曲
export const getTeacherEnjoySong = (params: any) => request('GET', '/teacher/enjoy-songs', params)

// 获取官方欣赏歌曲
export const getOfficialEnjoySong = (params: any) => request('GET', '/official/enjoy-songs', params)

// 获取官方律动歌曲1
export const getRhythmEnjoySong = (params: any) => request('GET', '/official/music-rhythms', params)
// 获取官方题库
export const getOfficialQuestionsBankSong = (params: any) => questionsBankRequest('GET', '/official/demo-questions', params)

export const getTeacherQuestionsBankSong = (params: any) => questionsBankRequest('GET', '/school/demo-questions', params)

export const getOfficialQuestionTag = (params: any) => questionsBankRequest('GET', '/official/tags', params)

export const getTeacherQuestionTag = (params: any) => questionsBankRequest('GET', '/school/tags', params)

// 获取律动歌曲
export const getMusicRhythm = async (params: any) => {
  return (await fetch(
    `${store.state.baseURL}/teacher/userMelodyFiles?name=${params.name}&type=${params.type}&per_page=${params.per_page}&page=${params.page}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${store.state.token}`
      }
    }
  )).json()
}

// 获取个人律动数据
export const getTeacherMusicRhythm = async (json_url: string) => {
  const res = await fetch(json_url)
  return await res.json()
}

// 获取题目组广场列表
export const getOfficialQGroup = (params: any) => request('GET', '/official/coursewares', params)
