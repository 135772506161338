<template>
  <a-modal
    :width="1000"
    :maskClosable="false"
    class="modal_style"
    :footer="null"
    :visible="visibleValue"
    title="欣赏歌曲"
    @cancel="handleCancel"
    :destroyOnClose="true"
    style="top: 20px"
  >
    <a-form layout="inline" @keydown.enter="handleSearchBtnClick">
      <a-row style="width: 100%" :gutter="[16, 16]">
        <a-col :md="8" :sm="24">
          <a-form-item label="歌曲名称">
            <a-input
              v-model:value="formData.name"
              placeholder="请输入歌曲名称"
            ></a-input>
          </a-form-item>
        </a-col>
        <!-- <a-col :md="8" :sm="24">
          <a-form-item label="歌曲编码">
            <a-input
              v-model:value="formData.song_code"
              placeholder="请输入歌曲编码"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="8" :sm="24">
          <a-form-item label="标签">
            <a-select
              v-model:value="formData.tag"
              mode="multiple"
              placeholder="可检索标签"
              style="width: 100%"
              :options="tagOpts"
              @search="getTagSearch"
            >
              <template #suffixIcon><search-outlined /></template>
            </a-select>
          </a-form-item>
        </a-col> -->
        <a-col :md="10">
          <a-form-item label="所属教材章节">
            <a
              v-if="bookShowName.length < 20"
              href="#"
              @click="handleShowBookModal"
            >
              {{ bookShowName }}
            </a>
            <a v-else href="#" @click="handleShowBookModal">
              <a-tooltip :title="bookShowName">
                {{ bookShowName.slice(0, 20) }}...
              </a-tooltip>
            </a>
          </a-form-item>
        </a-col>
        <a-col :md="6">
          <a-space>
            <a-button @click="handleResetForm"> 重置 </a-button>
            <!-- <a-button type="primary" @click="handleSearchBtnClick"> 查询 </a-button> -->
          </a-space>
        </a-col>
      </a-row>
    </a-form>
    <a-tabs v-model:activeKey="currentTabKey" @change=" handleResetForm" >
      <a-tab-pane v-if="tabSet.has('official')" key="official" tab="官方歌曲">
        <!-- 官方曲库列表 -->
        <a-table
          :data-source="dataList"
          :columns="cloumns"
          :loading="loading"
          rowKey="id"
          size="small"
          :pagination="paginations"
          :locale="locale"
        >
          <template #tags="{ text }">
            <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
          </template>
          <template #actions="{ record }">
            <a @click="() => handleAddSong(record)">添加</a>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane v-if="tabSet.has('personal')" key="personal" tab="我的歌曲">
        <a-table
          :data-source="dataList"
          :columns="cloumns"
          :loading="loading"
          rowKey="id"
          size="small"
          :pagination="paginations"
        >
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <select-book-modal
      ref="bookModalRef"
      :type="currentTabKey === 'official' ? 1: 2"
      v-model:visible="bookModalVisible"
      @ok="handleBookSelectOk"
    />
  </a-modal>
</template>

<script>
import { computed, onMounted, ref, createVNode, watch } from 'vue'
import { getInfoPermissionFromUser } from './commonUtils'
import * as service from '../service'
import { debounce } from '@/utils/common'
import { SearchOutlined } from '@ant-design/icons-vue'
import SelectBookModal from '@/views/songs/components/SelectBookModal.vue'
import Empty from '@/components/common/Empty.vue'

export default {
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  components: {
    // SearchOutlined,
    SelectBookModal
  },
  setup (props, { emit }) {
    const visibleValue = computed(() => props.visible)
    // tab列的key值
    const tabKey = getInfoPermissionFromUser()
    // computed属性的tabkey值的集合
    const tabSet = computed(() => new Set(tabKey))

    const currentTabKey = ref(tabKey[0] || null)

    // 列表数据
    const dataList = ref([])
    const handleCancel = () => {
      emit('onCancel')
    }
    let currentPage = 1
    // 页面变动
    const handlePageChange = (page) => {
      currentPage = page
      handleSearch()
    }
    const paginations = ref({ current: currentPage, pageSize: 10, onChange: handlePageChange })
    const loading = ref(false)

    const bookModalVisible = ref(false)
    const bookModalRef = ref()
    const bookShowName = ref('教材名称-章节目录')

    const handleBookSelectOk = (value) => {
      /**
       * 1。value 有值
       *  1. 选中教材 课时 book_id book_section_id=值 showmname=value.labels.join('-')
       *  2. 选中教材 book_id =值 book_section_id=undefined  showmname=value.labels[0]
       *  3. 选中课时   不存在
       * 2. value 没值
       *  1，没有选中value教材 课时  book_id =undefined book_section_id=undefined showmname=初始值的显示值
       */
      if (value.labels === undefined) {
        // handleResetForm()
        handleResetOk()
        bookModalVisible.value = false
      } else {
        console.log(value, '我是value')
        bookShowName.value = value.labels.join('-')
        formData.value.book_id = value.values[0]
        formData.value.book_section_id = value.values[1]
        bookModalVisible.value = false
        currentPage = 1
        handleSearchBtnClick()
      }
    }
    const handleShowBookModal = () => {
      bookModalVisible.value = true
    }

    const formData = ref({
      name: undefined,
      song_code: undefined,
      type: undefined,
      book_id: undefined,
      tag: []
    })
    // 监听 formData.name
    watch(() => formData.value.name, () => {
      handleSearchBtnClick()
    })
    const tagOpts = ref([])

    // 第一次进入页面
    const isFirst = ref(false)

    const getTagSearch = debounce(async (value) => {
      let SearchFunc = null
      if (currentTabKey.value === 'official') {
        SearchFunc = service.getOfficialTag
      } else if (currentTabKey.value === 'personal') {
        SearchFunc = service.getTeacherTags
      }
      if (SearchFunc !== null) {
        const tagList = await SearchFunc({ keyword: value, current: 1, per_page: 20 })
        tagOpts.value = tagList.items.map(item => { return { label: item.tag, value: item.tag } })
      }
    })

    const queryOfficialList = async (params) => {
      try {
        loading.value = true
        const data = await service.getOfficialEnjoySong({
          ...params,
          per_page: paginations.value.pageSize,
          page: currentPage,
          except_statuses: [2]
        })
        paginations.value = { ...paginations.value, ...data.meta, pageSize: +data.meta.per_page, current: data.meta.current_page }
        dataList.value = data.items
        loading.value = false
      } catch (error) {
        loading.value = false
        console.log(error)
        throw error
      }
    }

    const queryTeacherList = async (params) => {
      try {
        loading.value = true
        const data = await service.getTeacherEnjoySong({
          ...params,
          per_page: paginations.value.pageSize,
          except_statuses: 2,
          page: currentPage
        })
        paginations.value = { ...paginations.value, ...data.meta, pageSize: +data.meta.per_page, current: data.meta.current_page }
        dataList.value = data.items
        loading.value = false
      } catch (error) {
        loading.value = false
        console.log(error)
        throw error
      }
    }

    const handleSearchBtnClick = async () => {
      currentPage = 1
      await handleSearch()
    }

    const handleSearch = async () => {
      let searchFunc = null
      if (currentTabKey.value === 'official') {
        searchFunc = queryOfficialList
      } else if (currentTabKey.value === 'personal') {
        searchFunc = queryTeacherList
      }

      if (searchFunc !== null) {
        const params = formData.value
        await searchFunc(params)
      }
    }

    const handleResetForm = () => {
      formData.value = {
        name: undefined,
        song_code: undefined,
        type: undefined,
        tag: [],
        book_id: undefined,
        book_section_id: undefined
      }
      bookShowName.value = '教材名称-章节目录'
      getTagSearch('')
      currentPage = 1
      bookModalRef.value.handleReset()
      handleSearch()
    }
    // 当未选中书籍时候官方歌曲表单恢复初始值
    const handleResetOk = () => {
      formData.value = {
        name: undefined,
        song_code: undefined,
        type: undefined,
        tag: [],
        book_id: undefined,
        book_section_id: undefined
      }
      getTagSearch('')
      currentPage = 1
      bookShowName.value = '教材名称-章节目录'
      handleSearch()
    }
    const handleAddSong = (record) => {
      emit('onOk', { sid: record.id, source: currentTabKey.value })
      emit('onCancel')
    }

    onMounted(async () => {
      getTagSearch('')
      handleSearch().then(() => {
        isFirst.value = true
      })
    })

    // 检索为空提示
    const locale = {
      emptyText: createVNode(Empty, { value: isFirst.value })
    }

    watch(isFirst, (newValue) => {
      if (newValue === true) {
        locale.emptyText = createVNode(Empty, { value: isFirst.value })
      }
    })

    const cloumns = [
      {
        title: '歌曲名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 80
      },
      {
        title: '歌曲编码',
        dataIndex: 'song_code',
        key: 'song_code',
        width: 80,
        ellipsis: true
      },
      {
        title: '歌曲简拼',
        dataIndex: 'py',
        key: 'py',
        ellipsis: true,
        width: 50
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        slots: { customRender: 'tags' },
        width: 100,
        ellipsis: true
      },
      {
        title: '关联教材',
        dataIndex: 'book.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '教材出版社',
        dataIndex: 'book.press_version.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '关联教材章节',
        dataIndex: 'section.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 80,
        slots: { customRender: 'actions' }
      }
    ]

    return {
      visibleValue,
      tabSet,
      dataList,
      currentTabKey,
      cloumns,
      paginations,
      formData,
      tagOpts,
      loading,
      bookShowName,
      bookModalRef,
      bookModalVisible,
      handleBookSelectOk,
      handleShowBookModal,
      getTagSearch,
      handleSearch,
      handleSearchBtnClick,
      handleResetForm,
      handleAddSong,
      handleCancel,
      queryOfficialList,
      locale
    }
  }
}
</script>

<style>
.modal_style {
  min-height: 500px;
}
</style>
