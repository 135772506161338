import { useStore } from 'vuex'
import { dataPermission } from '../config.js'

// 获取当前用户可以读取的资源类型
export const getInfoPermissionFromUser = () => {
  const store = useStore()
  const userType = store.state.mainAppName
  const list = dataPermission.filter((item: { type: string }) => item.type === userType)
  if (list.length < 1) {
    return []
  }
  return list[0].permissions
}
