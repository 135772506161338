<template>
  <div ref="modal">
    <a-modal :getContainer="() => modal" :footer="null" width="550px" :visible="visibleValue" @cancel="handleCancel">
      <div class="searchDiv">
        <search-bar v-model:value="searchValue" :on-search="handleFilter" :on-change="handleFilter"/>
      </div>
      <div class="contentDiv">
        <a-tree
          v-if="(treeData.length !== 0)"
          checkable
          :tree-data="treeData"
          v-model:expandedKeys="expandedKeys"
          v-model:selectedKeys="selectedKeys"
          v-model:checkedKeys="checkedKeys"
          :autoExpandParent="autoExpandParent"
          @onExpand="onExpand"
        >
          <template #title="{ title }">
            <span v-if="title.indexOf(searchValue) > -1">
              {{ title.substr(0, title.indexOf(searchValue)) }}
              <span style="color: #f50">{{ searchValue }}</span>
              {{ title.substr(title.indexOf(searchValue) + searchValue.length) }}
            </span>
            <span v-else>{{ title }}</span>
          </template>
        </a-tree>
        <a-empty v-else>
          <template #description>
            <span> 暂无可关联的课件 </span>
          </template>
        </a-empty>
      </div>
      <div class="footer">
        <a-button type="primary" class="submitBtn" @click="handleSubmit">
          确定
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { message } from 'ant-design-vue'
import { defineEmits, reactive, onBeforeMount, defineProps, computed, watch, ref } from 'vue'
import SearchBar from '@/components/common/SearchBar'
import { useRoute } from 'vue-router'
import {
  getCoursewareInfo,
  getCoursewareVersionInfo
} from '@/services/courseware'
import * as service from '@/views/mine-coursewares/service'
import { debounce } from '@/utils/common'

const treeData = ref([
  { title: '我的课件', checkable: false, key: 0, children: [] }
])
const allData = ref([])
const expandedKeys = ref([0])
const selectedKeys = ref([])
const checkedKeys = ref([])
const checkedIds = ref([])
const checkedItems = ref([])

const autoExpandParent = ref(true)
const dataList = []

const modal = ref()

const route = useRoute()

const emit = defineEmits(['ok', 'onCancel', 'onOk'])

const props = defineProps({
  relateCoursewareIds: Array,
  relateCoursewares: Array,
  coursewareId: {
    type: Number,
    default: 0
  },
  relateType: String,
  visible: {
    type: Boolean,
    default: () => {
      return false
    }
  },
  coursewareType: {
    type: String,
    default: () => {
      return 'official'
    }
  }
})

const pageState = reactive({
  action: route.params.action,
  type: route.params.type,
  relateType: props.relateType,
  status_code: -1,
  id: props.coursewareId === 0 ? parseInt(route.params.id) : props.coursewareId,
  current: {
    relate_courseware_ids: []
  }
})
const searchValue = ref('')
const relateState = reactive({
  name: '',
  coursewareType: props.coursewareType,
  collected_ids: [],
  nameList: []
})

const onExpand = (keys) => {
  expandedKeys.value = keys
  autoExpandParent.value = false
}
const generateList = data => {
  for (let i = 0; i < data.length; i++) {
    const node = data[i]
    const key = node.key
    dataList.push({
      key,
      title: node.title
    })
    if (node.children) {
      generateList(node.children)
    }
  }
}

const getParentKey = (key, tree) => {
  let parentKey
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i]
    if (node.children) {
      if (node.children.some(item => item.key === key)) {
        parentKey = node.key
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children)
      }
    }
  }
  return parentKey
}

const handleAutoExpand = debounce((value) => {
  if (value === '' || !value) {
    expandedKeys.value = [0]
    autoExpandParent.value = true
    return
  }
  const expanded = dataList
    .map(item => {
      if (item.title.indexOf(value) > -1) {
        return getParentKey(item.key, treeData.value)
      }
      return null
    })
    .filter((item, i, self) => item && self.indexOf(item) === i)
  if (expanded.length === 0) {
    expanded.push(0)
  }
  expandedKeys.value = expanded
  autoExpandParent.value = true
}, 500)
watch(searchValue, value => {
  handleAutoExpand(value)
})

watch(() => props.relateCoursewareIds, async (val) => {
  pageState.current.relate_courseware_ids = props.relateCoursewareIds
  relateState.collected_ids = val
  checkedKeys.value = allData.value.filter(i => val.includes(i.courseware?.id)).map(i => i.id)
}, {
  immediate: true,
  deep: true
})

const visibleValue = computed(() => props.visible)

const handleCancel = () => {
  emit('onCancel')
}

const handleFilter = () => {
  console.log(searchValue)
}
// 构造菜单树数据
const getTreeData = (parent_id, menus) =>
  menus
    .filter(item => (item.parent_id ?? 0) === parent_id)
    .map(item => {
      if (menus.filter(menu => (menu.parent_id ?? 0) === item.id).length === 0) {
        return { title: item.name, key: item.id, checkable: item.type !== 0 }
      }
      return {
        checkable: item.type !== 0,
        title: item.name,
        key: item.id,
        children: getTreeData(item.id, menus)
      }
    })

const getRelateData = (items, pagination) => {
  treeData.value = [{
    checkable: false,
    title: '我的课件',
    key: 0,
    children: getTreeData(0, items)
  }]
  generateList(treeData.value)
}

const handleSubmit = async () => {
  // 编辑/新建教案或说课的情况
  if (pageState.relateType === 'ppt') {
    checkedItems.value = allData.value.filter(i => checkedKeys.value.includes(i.id))
    checkedIds.value = checkedItems.value.map(i => i.courseware?.id)
    localStorage.setItem('relate_ppts', JSON.stringify(checkedItems.value))
    emit('ok')
  } else {
    // 编辑PPT的情况
    if (relateState.coursewareType === 'teacher') {
      checkedItems.value = allData.value.filter(i => checkedKeys.value.includes(i.id))
      checkedIds.value = checkedItems.value.map(i => i.courseware?.id)
      let nowRelateCoursewareIds = []
      const relate_plan_ids = sessionStorage.getItem('relate_plan_ids')
      const relate_speak_ids = sessionStorage.getItem('relate_speak_ids')
      const planIds = JSON.parse(relate_plan_ids === null ? '' : relate_plan_ids)
      const speakIds = JSON.parse(relate_speak_ids === null ? '' : relate_speak_ids)
      if (pageState.relateType === 'lesson') {
        nowRelateCoursewareIds = [...new Set([...checkedIds.value, ...speakIds])]
      } else {
        nowRelateCoursewareIds = [...new Set([...checkedIds.value, ...planIds])]
      }
      await service.editTeacherCourseware(props.coursewareId, { relate_courseware_ids: nowRelateCoursewareIds })
      if (pageState.relateType === 'lesson') {
        sessionStorage.setItem('relate_plan_ids', JSON.stringify(checkedIds.value))
      } else {
        sessionStorage.setItem('relate_speak_ids', JSON.stringify(checkedIds.value))
      }
      pageState.status_code = 0
      message.success('关联成功')
      emit('onOk', { data: checkedItems.value, relateType: pageState.relateType })
    }
    // 新建PPT/编辑接口请求成功后的情况
    if (pageState.relateType === 'lesson') {
      const collectRelateInfo = relateState.nameList.filter(items => {
        return relateState.collected_ids.indexOf(items.value) !== -1
      })
      const relate_lessons = collectRelateInfo.map(({ value, label, type }) => ({ id: value, name: label, type: type }))
      sessionStorage.setItem('relate_lessons', JSON.stringify(relate_lessons))
    } else if (pageState.relateType === 'speak') {
      const collectRelateInfo = relateState.nameList.filter(items => {
        return relateState.collected_ids.indexOf(items.value) !== -1
      })
      const relate_speaks = collectRelateInfo.map(({ value, label, type }) => ({ id: value, name: label, type: type }))
      sessionStorage.setItem('relate_speaks', JSON.stringify(relate_speaks))
    }
    // 整理relate_courseware避免ppt切换tab时qiankun事件遍历的relate_courseware存在null
    const relate_lessons = JSON.parse(sessionStorage.getItem('relate_lessons'))
    const relate_speaks = JSON.parse(sessionStorage.getItem('relate_speaks'))
    let relate_coursewares
    if (!relate_lessons) {
      relate_coursewares = relate_speaks
    } else if (!relate_speaks) {
      relate_coursewares = relate_lessons
    } else if (relate_lessons && relate_speaks) {
      relate_coursewares = relate_lessons.concat(relate_speaks)
    }
    sessionStorage.setItem('relate_coursewares', JSON.stringify(relate_coursewares))
    // 回调数据给ppt-editor
    if (!props.coursewareId) {
      if (relateState.coursewareType === 'official') {
        emit('onOk', { relate_coursewares: relate_coursewares, coursewareSource: 'official', status_code: pageState.status_code, coursewareId: props.coursewareId })
      } else if (relateState.coursewareType === 'teacher') {
        emit('onOk', { relate_coursewares: relate_coursewares, coursewareSource: 'teacher', status_code: pageState.status_code, coursewareId: props.coursewareId })
      }
    }
  }
  emit('onCancel')
}

const getInfo = async () => {
  if (pageState.action === 'version-detail') {
    pageState.id = (await getCoursewareVersionInfo(pageState.id)).courseware_id
  }
  if (props.coursewareId) {
    pageState.id = props.coursewareId
  }
  if (!pageState.id) {
    return
  }
  if (relateState.coursewareType === 'teacher') {
    pageState.current = await service.getTeacherCourseware(pageState.id)
    pageState.current.relate_courseware_ids = pageState.current.relate_coursewares.map(item => item.id)
    checkedKeys.value = allData.value.filter(i => pageState.current.relate_courseware_ids.includes(i.courseware?.id)).map(i => i.id)
  } else if (relateState.coursewareType === 'official') {
    pageState.current = await getCoursewareInfo(pageState.id)
    pageState.current.relate_courseware_ids = pageState.current.relate_coursewares.map(item => item.id)
  }
}

const getList = async (params) => {
  // 当前页面为编辑/新建ppt页面
  if (pageState.relateType === 'lesson') { // 当前需要关联的课件为教案时
    if (relateState.coursewareType === 'teacher') {
      const { items, pagination } = await service.queryTeacherCoursewareList({ per_page: 9999 })
      const data = items.filter(i => i.type === 0 || (i.type === 1 && i.courseware.type === 'plan'))
      allData.value = data
      if (checkedKeys.value === 0) {
        const val = pageState.current.relate_courseware_ids
        checkedKeys.value = allData.value.filter(i => val.includes(i.courseware?.id)).map(i => i.id)
      }
      getRelateData(data, pagination)
    }
  } else if (pageState.relateType === 'speak') { // 当前需要关联的课件为说课时
    if (relateState.coursewareType === 'teacher') {
      const { items, pagination } = await service.queryTeacherCoursewareList({ per_page: 9999 })
      const data = items.filter(i => i.type === 0 || (i.type === 1 && i.courseware.type === 'speak'))
      allData.value = data
      if (checkedKeys.value === 0) {
        const val = pageState.current.relate_courseware_ids
        checkedKeys.value = allData.value.filter(i => val.includes(i.courseware?.id)).map(i => i.id)
      }
      getRelateData(data, pagination)
    }
  } else if (pageState.relateType === 'ppt') { // 当前需要关联的课件为说课时
    if (relateState.coursewareType === 'teacher') {
      const { items, pagination } = await service.queryTeacherCoursewareList({ per_page: 9999 })
      const data = items.filter(i => i.type === 0 || (i.type === 1 && i.courseware.type === 'ppt'))
      allData.value = data
      if (checkedKeys.value === 0) {
        const val = pageState.current.relate_courseware_ids
        checkedKeys.value = allData.value.filter(i => val.includes(i.courseware?.id)).map(i => i.id)
      }
      getRelateData(data, pagination)
    }
  }
}

onBeforeMount(async () => {
  await getList()
  await getInfo()
  pageState.current.relate_courseware_ids = props.relateCoursewareIds
  relateState.collected_ids = pageState.current.relate_courseware_ids
  if (pageState.current.relate_courseware_ids.length > 0) {
    checkedKeys.value = allData.value.filter(i => pageState.current.relate_courseware_ids.includes(i.courseware?.id)).map(i => i.id)
  }
})
</script>

<style scoped lang="less">
/deep/ .ant-modal-close-x {
  width: 30px;
  height: 30px;
  line-height: 30px;
}
/deep/ .ant-empty {
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-55%, -45%);
  .ant-empty-description {
    color: #b0b0b0d3;
  }
}
.searchDiv /deep/ .searchBar{
  padding: 0;
}
.searchDiv /deep/ .searchInput {
  width: 100%;
  height: 50px;
}
.searchDiv /deep/ .searchBtnDiv {
  display: none;
}
.searchDiv /deep/ .anticon-search {
  font-size: 30px;
}
.searchDiv /deep/ .ant-input {
  font-size: 20px;
}
.contentDiv {
  margin-top: 12px;
  min-height: 42vh;
  padding: 20px 20px 0 20px;
  max-height: 500px;
  overflow-y: scroll;
}
.contentDiv /deep/ .ant-checkbox-wrapper {
  display: block;
  line-height: 40px;
  font-size: 16px;
}

.footer{
  text-align: right;
  margin-top: 12px;
  // padding-right: 10px;
  transform: translateY(10px);
  .submitBtn{
    width: 88px;
    height: 36px;
    border-radius: 4px;
  }
}
.footer /deep/ .ant-pagination {
  display: inline-block;
  transform: translate(-8px, 8px);
}
.contentDiv /deep/ .ant-tree-child-tree-open > .ant-tree-treenode-switcher-close > .ant-tree-switcher-noop  {
  display: none;
}
</style>
