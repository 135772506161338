<template>
  <a-page-header title="预览课件" :subTitle="fileName" style="background-color: white">
    <template #extra>
      <a @click="handleGoBack">返回</a>
    </template>
  </a-page-header>
  <ppt-editor
    :resource="url"
    ref="pptEditor"
    type="preview"
    resourceType="teacher"
    width="100%"
    height="90vh"
    :autoHideMenu="true"
    is-teacher-mine="true"
  />
</template>

<script>
import PptEditor from '@/components/ppt-editor/PptEditor.vue'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'
import { eventCenter, event } from '@evideo/frontend-utils'
import { getTeacherCourseware } from './service'
import config from '@/config'

export default {
  components: { PptEditor },
  beforeRouteLeave: (to, from, next) => {
    eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, {
      app: config.pptist.name
    })
    setTimeout(() => {
      next()
    }, 0)
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const id = route.params.id
    const fileInfo = ref({})

    const pptEditor = ref()

    const fileName = ref('')

    const url = ref(null)

    const handleGoBack = () => {
      router.push('/resource/mine-coursewares')
    }

    onMounted(async () => {
      const data = await getTeacherCourseware(id)
      url.value = data.url
      fileName.value = data.name
      fileInfo.value = data
    })

    return {
      url,
      pptEditor,
      fileName,
      handleGoBack
    }
  }
}
</script>

<style>
</style>
