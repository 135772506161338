<template>
  <a-modal
    :width="1000"
    :maskClosable="false"
    title="音乐律动"
    :visible="visibleValue"
    @cancel="handleCancel"
    class="modal_style"
    :footer="null"
    :destroyOnClose="true"
    style="top: 10px"
  >
    <a-form layout="inline" @keydown.enter="handleSearchBtnClick">
      <a-row style="width: 100%" :gutter="[16, 16]">
        <a-col :md="8" :sm="24">
          <a-form-item label="律动名称">
            <a-input
              v-model:value="formData.name"
              placeholder="请输入律动名称"
            ></a-input>
          </a-form-item>
        </a-col>
        <a-col :md="16">
          <a-space>
            <a-button @click="handleResetForm"> 重置 </a-button>
            <a-button type="primary" @click="handleSearchBtnClick">
              查询
            </a-button>
          </a-space>
        </a-col>
      </a-row>
    </a-form>
    <a-tabs v-model:activeKey="currentTabKey" @change="handleResetForm">
      <a-tab-pane v-if="tabSet.has('official')" key="official" tab="律动广场">
        <!-- 官方曲库列表 -->
        <a-table
          :data-source="dataList"
          :columns="cloumns"
          :loading="loading"
          rowKey="id"
          size="small"
          :pagination="paginations"
          :locale="locale"
        >
          <template #rhythmtype="{ text }">
            <span>{{ MusicRhythmType[text] }}</span>
          </template>
          <template #tags="{ text }">
            <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
          </template>
          <template #actions="{ record }">
            <a @click="() => handleAddSong(record)">插入</a>
          </template>
        </a-table>
      </a-tab-pane>
      <a-tab-pane v-if="tabSet.has('teacher')" key="teacher" tab="我的音乐律动">
        <a-table
          :data-source="dataList"
          :columns="teacherColumns"
          :loading="loading"
          rowKey="id"
          size="small"
          :pagination="paginations"
          :locale="locale"
        >
          <template #actions="{ record }">
            <a @click="() => handleAddSong(record)">插入</a>
          </template>
        </a-table>
      </a-tab-pane>
    </a-tabs>
    <select-book-modal
      ref="bookModalRef"
      :type="currentTabKey === 'official' ? 1 : 2"
      v-model:visible="bookModalVisible"
      @ok="handleBookSelectOk"
    />
  </a-modal>
</template>

<script>
import { computed, onBeforeMount, ref, createVNode, watch } from 'vue'
import { getInfoPermissionFromUser } from './commonUtils'
import * as service from '../service'
import { MusicRhythmType } from '../config'
import SelectBookModal from '@/views/songs/components/SelectBookModal.vue'
import Empty from '@/components/common/Empty.vue'

export default {
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  components: {
    SelectBookModal
  },
  setup (props, { emit }) {
    // 弹窗可见性
    const visibleValue = computed(() => props.visible)
    // 关闭事件1
    const handleCancel = () => {
      emit('onCancel')
    }
    // tab列的key值1
    const tabKey = getInfoPermissionFromUser()
    // computed属性的tabkey值的集合
    const tabSet = computed(() => new Set(tabKey))

    const currentTabKey = ref(tabKey[0] || null)

    const formData = ref({
      name: '',
      type: currentTabKey.value === 'official' ? 2 : 1
    })
    // 监听 formData
    watch(() => formData.value.name, () => {
      handleSearchBtnClick()
    })
    const bookModalVisible = ref(false)
    const bookModalRef = ref()

    const handleBookSelectOk = () => {
      bookModalVisible.value = false
      currentPage = 1
    }

    // 列表数据
    const dataList = ref([])
    const cloumns = [
      {
        title: '歌曲名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 80
      },
      {
        title: '状态',
        dataIndex: 'status',
        slots: { customRender: 'rhythmtype' },
        key: 'status',
        width: 50
      },
      {
        title: '创建日期',
        dataIndex: 'created_at',
        key: 'created',
        width: 80
      },
      {
        title: '更新日期',
        dataIndex: 'updated_at',
        key: 'updated',
        width: 80
      },
      {
        title: '操作',
        key: 'action',
        width: 30,
        slots: { customRender: 'actions' }
      }
    ]
    const teacherColumns = [
      {
        title: '歌曲名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 80
      },
      {
        title: '创建日期',
        dataIndex: 'created_at',
        key: 'created',
        width: 80
      },
      {
        title: '更新日期',
        dataIndex: 'updated_at',
        key: 'updated',
        width: 80
      },
      {
        title: '操作',
        key: 'action',
        width: 30,
        slots: { customRender: 'actions' }
      }
    ]
    let currentPage = 1
    const handlePageChange = (page) => {
      currentPage = page
      handleSearch()
    }
    // 第一次进入页面
    const isFirst = ref(false)

    // 检索为空提示
    const locale = {
      emptyText: createVNode(Empty, { value: isFirst.value })
    }

    watch(isFirst, (newValue) => {
      if (newValue === true) {
        locale.emptyText = createVNode(Empty, { value: isFirst.value })
      }
    })

    const paginations = ref({ current: currentPage, pageSize: 10, onChange: handlePageChange })
    const loading = ref(false)

    const queryMusicRhythmList = async (params) => {
      try {
        loading.value = true
        const res = await service.getMusicRhythm({
          ...params,
          per_page: paginations.value.pageSize,
          page: currentPage
        })
        paginations.value = { ...paginations.value, ...res.meta, pageSize: +res.meta.per_page, current: res.meta.current_page }
        dataList.value = res.data
        loading.value = false
      } catch (error) {
        loading.value = false
        console.log(error)
        throw error
      }
    }

    const handleSearch = async () => {
      const params = formData.value
      await queryMusicRhythmList(params)
    }

    const handleSearchBtnClick = async () => {
      currentPage = 1
      await handleSearch()
    }

    const handleResetForm = () => {
      formData.value = {
        name: '',
        type: currentTabKey.value === 'official' ? 2 : 1
      }
      currentPage = 1
      bookModalRef.value.handleReset()
      handleSearch()
    }

    onBeforeMount(() => {
      handleSearch().then(() => {
        isFirst.value = true
      })
    })

    const handleAddSong = async (record) => {
      const data = {
        sid: record.id,
        source: currentTabKey.value
      }
      if (data.source === 'teacher') {
        const res = await service.getTeacherMusicRhythm(record.json_file_url)
        data.meta = {
          id: record.id,
          name: record.name,
          mp3_file_url: record.mp3_file_url,
          json_file_url: res,
          background_url: res.background_url,
          border_heightLight_url: res.border_heightLight_url,
          border_url: res.border_url
        }
      }
      emit('onOk', data)
      emit('onCancel')
    }

    return {
      visibleValue,
      currentTabKey,
      tabSet,
      dataList,
      cloumns,
      teacherColumns,
      paginations,
      loading,
      MusicRhythmType,
      formData,
      bookModalRef,
      bookModalVisible,
      handleSearchBtnClick,
      handleBookSelectOk,
      handleSearch,
      handleResetForm,
      handleCancel,
      handleAddSong,
      locale
    }
  }

}
</script>

<style>
.modal_style {
  min-height: 500px;
}
</style>
