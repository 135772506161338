<template>
  <div class="editor-body">
    <a-spin size="large" :spinning="loading" :tip="loadingText">
      <div
        :style="{ width: widthProp, height: heightProp, ...pptStyleProps }"
        ref="pptElement"
      ></div>
    </a-spin>
    <knowledge-point-modal
      v-if="knowledgeModalVisible"
      :visible="knowledgeModalVisible"
      @onCancel="handleKnowledgeModalCancel"
      @onOk="handleKnowledgeModalOk"
    />
    <sheet-song-modal
      v-if="sheetSongModalVisible"
      :visible="sheetSongModalVisible"
      @onCancel="handleSheetSongModalCancel"
      @onOk="handleSheetSongModalOk"
    />
    <enjoy-song-modal
      v-if="enjoySongModalVisible"
      :visible="enjoySongModalVisible"
      @onCancel="handleEnjoySongModalCancel"
      @onOk="handleEnjoySongModalOk"
    />
    <music-rhythm-modal
      v-if="musicRhythmModalVisible"
      :visible="musicRhythmModalVisible"
      @onCancel="handleMusicRhythmModalCancel"
      @onOk="handleMusicRhythmModalOk"
    ></music-rhythm-modal>
    <questions-bank-modal
      v-if="questionsBankModalVisible"
      :visible="questionsBankModalVisible"
      @onCancel="handleQuestionsBankModalCancel"
      @onOk="handleQuestionsBankModalOk"
    ></questions-bank-modal>
    <QGroupModal
      v-if="qGroupModalVisible"
      :visible="qGroupModalVisible"
      @onCancel="handleQGroupModalCancel"
      @onOk="handleQGroupModalOk"
    ></QGroupModal>
    <relate-courseware-modal
      v-if="relateCoursewareModalVisible && !isTeacherMineProps"
      :visible="relateCoursewareModalVisible"
      :coursewareId="relatePlanOrSpeak.coursewareId"
      :relate-coursewares="relatePlanOrSpeak.relate_coursewares || []"
      :relateType="relatePlanOrSpeak.type"
      :coursewareType="relatePlanOrSpeak.coursewareSource"
      @onCancel="handleRelateCoursewareModalCancel"
      @onOk="handleRelateCoursewareModalOk"
    />
    <select-relate-teacher-mine-courseware-modal
      v-if="relateCoursewareModalVisible && isTeacherMineProps"
      :visible="relateCoursewareModalVisible"
      :coursewareId="relatePlanOrSpeak.coursewareId"
      :relate-courseware-ids="relatePlanOrSpeak.relate_courseware_ids || []"
      :relateType="relatePlanOrSpeak.type"
      :coursewareType="relatePlanOrSpeak.coursewareSource"
      @onCancel="handleRelateCoursewareModalCancel"
      @onOk="handleMineRelateCoursewareModalOk"
    />
  </div>
</template>

<script>
import {
  ActionType,
  ResourceType,
  SongModalType,
  EditPptModeRuntimeConfig,
  EditPptModeRuntimeConfigWithoutExport,
  PreviewPptModeRuntimeConfig,
  MainAppNameMap
} from './config'
import { computed, createVNode, onMounted, ref, watch, reactive } from 'vue'
import { event, eventCenter } from '@evideo/frontend-utils'
import config from '@/config'
import { useStore } from 'vuex'
import KnowledgePointModal from './components/KnowledgePointModal.vue'
import SheetSongModal from './components/SheetSongModal.vue'
import EnjoySongModal from './components/EnjoySongModal.vue'
import MusicRhythmModal from './components/MusicRhythmModal.vue'
import QuestionsBankModal from '@/components/ppt-editor/components/QuestionsBankModal'
import RelateCoursewareModal from '@/views/courseware/components/SelectRelateCoursewareModal.vue'
import SelectRelateTeacherMineCoursewareModal from '@/views/courseware/components/SelectRelateTeacherMineCoursewareModal.vue'
import QGroupModal from './components/QGroupModal.vue'
import { Modal, message } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { useRoute } from 'vue-router'
import {
  editCourseware,
  getCoursewareVersionInfo
} from '@/services/courseware'
import * as service from '@/views/mine-coursewares/service'

export default {
  components: {
    KnowledgePointModal,
    SheetSongModal,
    EnjoySongModal,
    MusicRhythmModal,
    QuestionsBankModal,
    QGroupModal,
    RelateCoursewareModal,
    SelectRelateTeacherMineCoursewareModal
  },
  props: {
    // 资源地址
    resource: {
      type: String || null,
      default: () => null
    },
    // ppt文件名称
    pptName: {
      type: String || null,
      default: () => ''
    },
    type: {
      type: String,
      default: () => ActionType.PREVIEW
    },
    // 是否启用全屏加载,若启用,则loading事件交由父组件负责实现
    fullLoad: {
      type: Boolean,
      default: () => false
    },
    // 资源的来源
    resourceType: {
      type: String,
      default: () => ResourceType.OFFICIAL
    },
    // 宽度
    width: {
      type: String,
      default: () => '100%'
    },
    // 高度
    height: {
      type: String,
      default: () => '100%'
    },
    // ppt标签的样式
    pptStyleProps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 是否自动隐藏侧边菜单
    autoHideMenu: {
      type: Boolean,
      default: () => false
    },
    // 判断校端课件库来源
    from: {
      type: String,
      default: () => null
    },
    ids: {
      type: Number,
      default: () => null
    },
    isTeacherMine: {
      type: Boolean,
      default: () => false
    }
  },
  emits: ['onSave', 'onFullScreen', 'onLoading', 'onChange'],
  setup(props, { emit }) {
    const route = useRoute()
    const id = parseInt(route.params.id || route.params.recordId)
    let coursewareId = id
    const createPptLock = props.resource === ''
    const isTeacherMineProps = ref(props.isTeacherMine)

    // ppt挂在的节点
    const pptElement = ref()

    // 操作类型: 预览还是编辑
    const actionType = computed(() => props.type)

    // 资源加载地址
    const resourceUrl = computed(() => props.resource)
    // ppt文件名称
    const pptName = computed(() => props.pptName)
    // 是否交由父类全屏loading
    const isFullLoad = computed(() => props.fullLoad)

    // 组件loading状态
    const loading = ref(false)

    // 组件loading文字
    const loadingText = ref('')

    // 组件宽度
    const widthProp = computed(() => props.width)

    // 高度
    const heightProp = computed(() => props.height)

    // 是否自动隐藏菜单
    const autoHideMenuProp = computed(() => props.autoHideMenu)

    // 校端课件来源
    const fromData = computed(() => props.from)
    const fromNum = computed(() => props.ids)

    // 保存上一个定时器
    let lastTimer

    // 欣赏歌曲弹窗可见性
    const enjoySongModalVisible = ref(false)
    // 欣赏歌曲弹窗回调
    let enjoySongModalCallBack = null
    // 谱例歌曲弹窗可见性
    const sheetSongModalVisible = ref(false)
    // 谱例歌曲弹窗回调
    let sheetSongModalCallBack = null
    // 知识点弹窗可见性
    const knowledgeModalVisible = ref(false)
    // 知识点弹窗回调
    let knowledgeModalCallBack = null
    // 节奏律弹窗可见性
    const musicRhythmModalVisible = ref(false)
    // 节奏律动弹窗回调
    let musicRhythmModalCallBack = null
    // 题库弹窗可见性
    const questionsBankModalVisible = ref(false)
    // 题库弹窗回调
    let questionsBankModalCallBack = null

    // 题目组弹窗可见性
    const qGroupModalVisible = ref(false)
    // 题目组弹窗回调
    let qGroupModalCallBack = null

    const relateValue = ref()
    // 关联教案/说课弹框可见性
    const relateCoursewareModalVisible = ref(false)
    // 编辑关联教案/说课弹框回调
    let relateCoursewareModalCallBack = null
    // 新建关联教案/说课弹框回调
    let getNewPptDetailsCallBack = null
    // 关联弹框的数据
    const relatePlanOrSpeak = reactive({
      type: 'lesson',
      coursewareId: id,
      coursewareSource: props.resourceType,
      relate_coursewares: [],
      relate_courseware_ids: [],
      status_code: -1
    })

    const store = useStore()

    // 父应用平台
    const mainAppName = store.state.mainAppName

    // 启动的参数配置
    const loadAction = {
      edit: {
        screening: false,
        screenType: 'edit-check',
        exitScreenType: 'edit-check'
      },
      preview: {
        screening: true,
        screenType: 'preview',
        exitScreenType: 'preview'
      }
    }

    let loadPPTResolveFunc = null
    const loadPPTPromise = new Promise(resolve => {
      loadPPTResolveFunc = resolve
    })

    // 是否全屏
    const isFullScreen = () => {
      return (
        document.mozFullScreen ||
        document.webkitIsFullScreen ||
        document.webkitFullScreen
      )
    }

    const checkHideMenu = () => {
      if (autoHideMenuProp.value) {
        eventCenter.send(event.COMMON_CHANGE_COLLPASED, { collapsed: true })
      }
    }

    // 修改组件loading状态
    const handleShowLoading = (value = false, text = '', timeout = 3000) => {
      loadingText.value = text
      loading.value = value
      if (value) {
        // 根据timeout时间清楚loading状态
        const timer = setInterval(() => {
          loading.value = false
          loadingText.value = ''
          // 定时到了,清楚对应的数据后,要将定时器清理掉
          clearInterval(timer)
        }, timeout)
        // 如果旧的定时器存在,需要释放掉,然后存入新的定时器
        if (lastTimer) {
          clearInterval(lastTimer)
        }
        lastTimer = timer
      } else {
        // 主动调用
        loading.value = false
        loadingText.value = ''
        // 去掉对应的定时器
        clearInterval(lastTimer)
        lastTimer = undefined
      }
    }

    const handleShowLoadingWithoutTimeOut = (value, tips) => {
      loading.value = value
      loadingText.value = tips
    }

    // 知识点弹窗关闭
    const handleKnowledgeModalCancel = () => {
      knowledgeModalVisible.value = false
    }

    // 弹窗选择结束
    const handleKnowledgeModalOk = data => {
      if (knowledgeModalCallBack) {
        knowledgeModalCallBack.reply(data)
      }
    }

    // 谱例歌曲弹窗关闭
    const handleSheetSongModalCancel = () => {
      sheetSongModalVisible.value = false
    }

    // 谱例歌曲选择结束
    const handleSheetSongModalOk = data => {
      if (sheetSongModalCallBack) {
        sheetSongModalCallBack.reply(data)
      }
    }

    // 欣赏歌曲弹窗关闭
    const handleEnjoySongModalCancel = () => {
      enjoySongModalVisible.value = false
    }

    // 欣赏歌曲弹窗回调
    const handleEnjoySongModalOk = data => {
      if (enjoySongModalCallBack) {
        enjoySongModalCallBack.reply(data)
      }
    }

    // 节奏律动弹窗关闭
    const handleMusicRhythmModalCancel = () => {
      musicRhythmModalVisible.value = false
    }

    // 节奏律动弹窗回调
    const handleMusicRhythmModalOk = data => {
      if (musicRhythmModalCallBack) {
        musicRhythmModalCallBack.reply(data)
      }
    }

    // 题库弹窗关闭
    const handleQuestionsBankModalCancel = () => {
      questionsBankModalVisible.value = false
    }
    // 题库弹窗回调
    const handleQuestionsBankModalOk = data => {
      if (questionsBankModalCallBack) {
        questionsBankModalCallBack.reply(data)
      }
    }

    // 题目组弹窗关闭
    const handleQGroupModalCancel = () => {
      qGroupModalVisible.value = false
    }
    // 题目组弹窗回调
    const handleQGroupModalOk = data => {
      if (qGroupModalCallBack) {
        qGroupModalCallBack.reply(data)
      }
    }

    // 关联说课/教案弹窗关闭
    const handleRelateCoursewareModalCancel = () => {
      relateCoursewareModalVisible.value = false
    }

    // 关联说课/教案弹窗回调
    const handleRelateCoursewareModalOk = (data, info) => {
      relateValue.value = info
      if (id !== 0) {
        const { relate_coursewares } = data
        relatePlanOrSpeak.relate_coursewares = relate_coursewares
        if (relateCoursewareModalCallBack) {
          relateCoursewareModalCallBack.reply(data)
        }
      } else if (id === 0) {
        if (relateCoursewareModalCallBack && getNewPptDetailsCallBack) {
          const relate_coursewares = JSON.parse(
            sessionStorage.getItem('relate_coursewares')
          )
          if (relate_coursewares) {
            relatePlanOrSpeak.relate_coursewares = relate_coursewares
            relateCoursewareModalCallBack.reply(data)
            getNewPptDetailsCallBack.reply(data)
          } else {
            relateCoursewareModalCallBack.reply(data)
            getNewPptDetailsCallBack.reply(data)
            relatePlanOrSpeak.relate_coursewares = []
          }
          message.success('关联成功')
        }
      }
    }

    const handleMineRelateCoursewareModalOk = (res, info) => {
      const { data, relateType } = res
      console.log('999关联回调', relateType, data)
      relateValue.value = info
      if (id !== 0) {
        if (relateCoursewareModalCallBack) {
          relateCoursewareModalCallBack.reply(data)
        }
      } else if (id === 0) {
        if (relateCoursewareModalCallBack && getNewPptDetailsCallBack) {
          const relate_coursewares = JSON.parse(
            sessionStorage.getItem('relate_coursewares')
          )
          relatePlanOrSpeak.relate_courseware_ids = relate_coursewares.map(
            i => i.id
          )
          relateCoursewareModalCallBack.reply(data)
          getNewPptDetailsCallBack.reply({
            relate_coursewares: relate_coursewares
          })
          message.success('关联成功')
        }
      }
    }

    // 加载一份ppt
    const handleLoadPPT = () => {
      // 焦点聚焦回顶部
      window.scrollTo(0, 0)
      // 发送加载ppt时间
      console.timeEnd('PPTIST_LOAD_PPT handleLoadPPT')
      const runtime = getRuntimeConfig()
      eventCenter.send(event.PPTIST_LOAD_PPT, {
        url: resourceUrl.value || undefined,
        ...loadAction[actionType.value],
        runtime
      })
      console.timeEnd('PPTIST_LOAD_PPT之前耗时')
      emit('onChange', resourceUrl.value)
      // handleShowLoading(true, '加载数据中', 2000)
    }

    // 注册应用加载成功监听
    const registerLoadAppOk = () => {
      eventCenter.sub(`${config.pptist.name}-load-ok`, () => {
        if (loadPPTResolveFunc) {
          loadPPTResolveFunc()
        }
      })
    }

    const getRuntimeConfig = () => {
      let runtime = ''
      if (
        actionType.value === ActionType.EDIT &&
        mainAppName === MainAppNameMap.SHENYUE
      ) {
        runtime = EditPptModeRuntimeConfig
      }
      if (
        actionType.value === ActionType.EDIT &&
        mainAppName === MainAppNameMap.TEACHER
      ) {
        runtime = EditPptModeRuntimeConfigWithoutExport
        runtime.editPptRule = {
          mode: 'onlyPpt'
        }
      }
      if (actionType.value === ActionType.PREVIEW) {
        runtime = PreviewPptModeRuntimeConfig
      }

      if (mainAppName === MainAppNameMap.INS) {
        runtime.extData.coursewareSource = 'institution'
      } else {
        runtime.extData.coursewareSource = props.resourceType
      }
      runtime.extData.coursewareSource = props.resourceType

      if (fromNum.value) {
        runtime.extData.coursewareId = fromNum.value
      } else {
        if (route.params.action === 'version-detail') {
          runtime.extData.pptVersionId = id
          runtime.extData.coursewareId = coursewareId
        }
        runtime.extData.coursewareId = coursewareId
      }
      runtime.extData.pptName = pptName.value
      console.log('---relate---extData', runtime.extData)
      console.log('---relate---extData', props.resourceType)
      return runtime
    }

    // 组件核心的流程
    const handleLoadPptistApp = () => {
      handleShowLoadingWithoutTimeOut(true, '初始化应用中')
      // 第一次加载
      const pptEntryUrl = config.pptist.entry

      const runtimeConfig = getRuntimeConfig()

      eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
        app: config.pptist.name,
        el: pptElement.value,
        entry: pptEntryUrl,
        args: {
          token: store.state.token,
          microAppUrl: pptEntryUrl,
          appName: config.pptist.name,
          runtime: runtimeConfig
        },
        ...loadAction[actionType.value]
      })
    }

    // 注册全屏监听
    const registerFullScreenListen = () => {
      eventCenter.sub(event.COMMON_FULL_SCREEN, res => {
        const element = pptElement.value
        if (res.fullScreen === true) {
          if (!isFullScreen()) {
            // 进入全屏
            if (element.requestFullScreen) {
              element.requestFullScreen()
            } else if (element.mozRequestFullScreen) {
              element.mozRequestFullScreen()
            } else if (element.webkitRequestFullscreen) {
              element.webkitRequestFullscreen()
            } else if (element.msRequestFullscreen) {
              element.msRequestFullscreen()
            }
          }
        } else {
          if (document.exitFullScreen) {
            document.exitFullScreen()
          } else if (document.msExitFullScreen) {
            document.msExitFullScreen()
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen()
          }
        }
        emit('onFullScreen')
      })
    }

    // 注册监听事件
    const registerSaveListen = () => {
      eventCenter.sub(event.RESOURCE_UPDATE_RESOURCE, (res, callbackEvent) => {
        console.log('[传给handleSavePPT的data与callback]', res, callbackEvent)
        emit('onSave', res, callbackEvent, relateValue)
      })
    }

    // 注册全局的加载事件
    const registerFullLoadingListen = () => {
      eventCenter.sub(event.COMMON_SHOW_LOADING, res => {
        if (isFullLoad.value) {
          emit('onLoading', res)
        } else {
          const { loading, timeout, loadingText } = res
          handleShowLoading(loading, loadingText, timeout)
        }
      })
    }

    // 注册弹窗监听事件
    // 目前弹窗类型：欣赏歌曲、谱例歌曲、知识点、关联课件、答题PK题目组
    const registerModalListen = () => {
      // 歌曲的弹窗
      eventCenter.sub(event.RESOURCE_OPEN_SONG_MODAL, (res, callbackEvent) => {
        const { type: songType } = res

        if (songType === SongModalType.SHEET) {
          sheetSongModalVisible.value = true
          sheetSongModalCallBack = callbackEvent
        } else if (songType === SongModalType.ENJOY) {
          enjoySongModalVisible.value = true
          enjoySongModalCallBack = callbackEvent
        } else if (songType === SongModalType.RHYTHM) {
          musicRhythmModalVisible.value = true
          musicRhythmModalCallBack = callbackEvent
        } else if (songType === SongModalType.QUESTIONS) {
          questionsBankModalVisible.value = true
          questionsBankModalCallBack = callbackEvent
        } else if (songType === SongModalType.QGROUP) {
          qGroupModalVisible.value = true
          qGroupModalCallBack = callbackEvent
        }
      })
      // 知识点弹窗
      eventCenter.sub(
        event.RESOURCE_OPEN_KNOWLEDGE_POINTS_MODAL,
        (res, callbackEvent) => {
          knowledgeModalVisible.value = true
          knowledgeModalCallBack = callbackEvent
        }
      )
      // 关联说课/教案弹窗
      eventCenter.sub('openLessonOrSpeakModal', (res, callbackEvent) => {
        const { type, coursewareId } = res
        relatePlanOrSpeak.type = type
        relatePlanOrSpeak.coursewareId = coursewareId
        relateCoursewareModalVisible.value = true
        relateCoursewareModalCallBack = callbackEvent
      })

      eventCenter.sub('getNewPptDetails', (res, callbackEvent) => {
        getNewPptDetailsCallBack = callbackEvent
        const relate_coursewares = JSON.parse(
          sessionStorage.getItem('relate_coursewares')
        )
        if (relate_coursewares) {
          getNewPptDetailsCallBack.reply({
            relate_coursewares: relate_coursewares
          })
        }
      })

      // todo 取消关联事件
      eventCenter.sub(
        'cancelLessonOrSpeakRelate',
        async (res, callbackEvent) => {
          // 先从会话存储中读取关联的课件信息
          let relate_lessons = JSON.parse(
            sessionStorage.getItem('relate_lessons')
          )
          let relate_speaks = JSON.parse(
            sessionStorage.getItem('relate_speaks')
          )
          let relate_coursewares = JSON.parse(
            sessionStorage.getItem('relate_coursewares')
          )

          console.log('999[关联课件]取消关联事件', res, callbackEvent)
          const { coursewareId, relate_courseware_ids, type } = res
          if (
            relate_coursewares &&
            Object.keys(relate_coursewares).length !== 0
          ) {
            relatePlanOrSpeak.relate_coursewares = relate_coursewares.filter(
              item => {
                return relate_courseware_ids.indexOf(item.id) !== -1
              }
            )
          }
          sessionStorage.setItem(
            'delete_data',
            JSON.stringify(relate_courseware_ids)
          )
          // 一、在编辑ppt时取消关联的情况：直接调用接口
          if (coursewareId !== 0) {
            if (relatePlanOrSpeak.coursewareSource === 'official') {
              await editCourseware(coursewareId, {
                relate_courseware_ids: relate_courseware_ids
              })
              relatePlanOrSpeak.status_code = 0
              callbackEvent.reply({
                status_code: relatePlanOrSpeak.status_code,
                coursewareSource: 'official',
                coursewareId: coursewareId
              })
              message.success('取消关联成功')
            } else if (relatePlanOrSpeak.coursewareSource === 'teacher') {
              relateValue.value = res.relate_courseware_ids
              const nowRelateCoursewareIds = relate_courseware_ids
              const relate_plan_ids = sessionStorage.getItem('relate_plan_ids')
              const relate_speak_ids = sessionStorage.getItem(
                'relate_speak_ids'
              )
              const planIds = JSON.parse(
                relate_plan_ids === null ? '' : relate_plan_ids
              )
              const speakIds = JSON.parse(
                relate_speak_ids === null ? '' : relate_speak_ids
              )
              if (type === 'lesson') {
                sessionStorage.setItem(
                  'relate_plan_ids',
                  JSON.stringify(
                    planIds.filter(i => relate_courseware_ids.includes(i))
                  )
                )
              } else {
                sessionStorage.setItem(
                  'relate_speak_ids',
                  JSON.stringify(
                    speakIds.filter(i => relate_courseware_ids.includes(i))
                  )
                )
              }
              await service.editTeacherCourseware(coursewareId, {
                relate_courseware_ids: nowRelateCoursewareIds
              })
              relatePlanOrSpeak.status_code = 0
              callbackEvent.reply({
                status_code: relatePlanOrSpeak.status_code,
                coursewareSource: 'teacher',
                coursewareId: coursewareId
              })
              message.success('取消关联成功')
            }
          }
          // 二、在新建ppt时取消关联的情况
          // 1. 将取消关联后的已关联课件信息存入对应的会话存储
          if (type === 'lesson' && relate_lessons) {
            // 取消关联教案类型的课件
            relate_lessons = relate_lessons.filter(
              list => relate_courseware_ids.indexOf(list.id) !== -1
            )
            sessionStorage.setItem(
              'relate_lessons',
              JSON.stringify(relate_lessons)
            )
          } else if (type === 'speak' && relate_speaks) {
            // 取消关联说课类型的课件
            relate_speaks = relate_speaks.filter(
              list => relate_courseware_ids.indexOf(list.id) !== -1
            )
            sessionStorage.setItem(
              'relate_speaks',
              JSON.stringify(relate_speaks)
            )
          }
          // 2. 将取消关联后剩余的已关联课件信息存入relate_coursewares
          if (relate_coursewares) {
            relate_coursewares = relate_coursewares.filter(
              list => relate_courseware_ids.indexOf(list.id) !== -1
            )
            sessionStorage.setItem(
              'relate_coursewares',
              JSON.stringify(relate_coursewares)
            )
          }
          // 三、将处理过后的数据回调给ppt
          if (coursewareId === 0) {
            if (relatePlanOrSpeak.coursewareSource === 'official') {
              callbackEvent.reply({
                coursewareSource: 'official',
                coursewareId: id,
                relate_coursewares: relate_coursewares
              })
              message.success('取消关联成功')
            } else if (relatePlanOrSpeak.coursewareSource === 'teacher') {
              callbackEvent.reply({
                coursewareSource: 'teacher',
                coursewareId: id,
                relate_coursewares: relate_coursewares
              })
              message.success('取消关联成功')
            }
          }
        }
      )
    }

    const checkAnShowSaveWarning = next => {
      eventCenter.send(event.PPTIST_IS_MODIFIED, null, ({ isModified }) => {
        if (isModified) {
          Modal.confirm({
            title: '有尚未保存的ppt内容，确定要离开吗?',
            icon: createVNode(ExclamationCircleOutlined),
            onOk() {
              next()
              sessionStorage.clear()
            }
          })
        } else {
          next()
          sessionStorage.clear()
        }
      })
    }

    // resource变化时自动加载
    watch(resourceUrl, newValue => {
      if (createPptLock) {
        return
      }
      loadPPTPromise.then(() => {
        handleShowLoading(false, '')
        handleLoadPPT()
        emit('onChange', newValue)
      })
    })

    onMounted(async () => {
      registerFullScreenListen()
      registerSaveListen()
      registerModalListen()
      registerFullLoadingListen()
      registerLoadAppOk()
      checkHideMenu()
      if (route.params.action === 'version-detail') {
        const res = await getCoursewareVersionInfo(id)
        coursewareId = res.courseware_id
      }
      relatePlanOrSpeak.coursewareId = coursewareId
      console.time('PPTIST_LOAD_PPT handleLoadPPT')

      handleLoadPptistApp()
      console.time('PPTIST_LOAD_PPT loadPPTPromise')
      if (resourceUrl.value || resourceUrl.value === '') {
        loadPPTPromise.then(() => {
          console.timeEnd('PPTIST_LOAD_PPT loadPPTPromise')
          handleShowLoading(false, '')
          handleLoadPPT()
        })
      }
    })

    return {
      isTeacherMineProps,
      pptElement,
      actionType,
      resourceUrl,
      loading,
      loadingText,
      widthProp,
      heightProp,
      fromData,
      fromNum,
      relatePlanOrSpeak,
      knowledgeModalVisible,
      sheetSongModalVisible,
      enjoySongModalVisible,
      musicRhythmModalVisible,
      questionsBankModalVisible,
      relateCoursewareModalVisible,
      qGroupModalVisible,
      // 这里是为了暴露给父组件手动调用
      handleLoadPPT,
      handleSheetSongModalCancel,
      handleKnowledgeModalCancel,
      handleEnjoySongModalCancel,
      handleMusicRhythmModalCancel,
      handleQuestionsBankModalCancel,
      handleRelateCoursewareModalCancel,
      handleKnowledgeModalOk,
      handleSheetSongModalOk,
      handleEnjoySongModalOk,
      handleMusicRhythmModalOk,
      handleQuestionsBankModalOk,
      handleRelateCoursewareModalOk,
      checkAnShowSaveWarning,
      handleMineRelateCoursewareModalOk,
      handleQGroupModalCancel,
      handleQGroupModalOk
    }
  }
}
</script>

<style>
.editor-body {
  width: 100%;
  height: 100%;
}
</style>
