<template>
  <a-modal
    title="选择知识点"
    :width="1000"
    :maskClosable="false"
    :footer="null"
    :visible="visibleCompu"
    :destroyOnClose="true"
    style="top: 20px"
    @cancel="handelCancel"
  >
    <a-spin :spinning="queryLoading">
      <a-form layout="inline" @keydown.enter="handleSearchBtnClick">
        <a-row style="width: 100%" :gutter="[16, 16]">
          <a-col :md="8" :sm="24">
            <a-form-item label="名称">
              <a-input v-model:value="formData.name"></a-input>
            </a-form-item>
          </a-col>
          <a-col :md="6" :sm="24">
            <a-space>
              <a-button @click="handleResetForm"> 重置 </a-button>
              <a-button type="primary" @click="handleSearchBtnClick">
                查询
              </a-button>
            </a-space>
          </a-col>
        </a-row>
      </a-form>
      <a-tabs v-model:activeKey="currentTabKey" @change="handleTabChange">
        <a-tab-pane
          v-for="tabKey in tabKeys"
          :key="tabKey"
          :tab="`${tabKeyName[tabKey]}`"
        ></a-tab-pane>
      </a-tabs>
      <a-table :columns="columns" :pagination="pagination" :dataSource="list" rowKey="id" :locale="locale">
        <template #tags="{ text }">
          <a-tag :key="item.tag" v-for="item in text">{{ item.tag }}</a-tag>
        </template>
        <template #actions="{ record }">
          <a @click="() => handleAddKnowledgePoint(record)">添加</a>
        </template>
      </a-table>
    </a-spin>
  </a-modal>
</template>

<script>
import { computed, onMounted, ref, toRaw, createVNode, watch } from 'vue'
import { getInfoPermissionFromUser } from './commonUtils'
import * as service from '../service'
import { tabKeyName } from '../config'
import { message } from 'ant-design-vue'
import Empty from '@/components/common/Empty.vue'

export default {
  props: {
    visible: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  emits: ['onOk', 'onCancel'],
  setup (props, { emit }) {
    const visibleCompu = computed(() => props.visible)

    const tabKeys = ref(getInfoPermissionFromUser())
    const currentTabKey = ref(tabKeys.value[0] || 'official')

    const formData = ref({
      name: undefined
    })

    const list = ref([])

    const queryLoading = ref(false)

    const columns = ref([
      {
        title: '名称',
        dataIndex: 'name',
        key: 'name',
        ellipsis: true,
        width: 80
      },
      {
        title: '关联教材',
        dataIndex: 'book.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '教材出版社',
        dataIndex: 'book.press_version.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '关联教材章节',
        dataIndex: 'section.name',
        key: 'book.name',
        ellipsis: true,
        width: 80
      },
      {
        title: '标签',
        dataIndex: 'tags',
        key: 'tags',
        slots: { customRender: 'tags' },
        width: 100,
        ellipsis: true
      },
      {
        title: '操作',
        key: 'action',
        fixed: 'right',
        width: 80,
        slots: { customRender: 'actions' }
      }
    ])
    // 第一次进入页面
    const isFirst = ref(false)

    // 当前页码
    let currentPage = 1

    // 检索为空提示
    const locale = {
      emptyText: createVNode(Empty, { value: isFirst.value })
    }

    watch(isFirst, (newValue) => {
      if (newValue === true) {
        locale.emptyText = createVNode(Empty, { value: isFirst.value })
      }
    })

    // 页码变换
    const handlePageChange = (page) => {
      currentPage = page
      handleSearch()
    }

    const pagination = ref({ current: currentPage, pageSize: 10, onChange: handlePageChange })

    // 获取列表数据
    const handleSearch = async (params = {}) => {
      let searchFunc = null
      const paramsData = {
        ...params,
        per_page: pagination.value.pageSize,
        page: currentPage
      }
      if (currentTabKey.value === 'official') {
        searchFunc = service.queryOfficialKnowledgePoint
      } else if (currentTabKey.value === 'teacher') {
        searchFunc = service.queryTeacherKnowledgePoint
      }
      if (searchFunc) {
        try {
          queryLoading.value = true
          const queryData = await searchFunc(paramsData)
          list.value = queryData.items
          pagination.value = {
            ...toRaw(pagination.value),
            ...queryData.meta,
            pageSize: +queryData.meta.per_page,
            current: queryData.meta.current_page
          }

          queryLoading.value = false
        } catch (error) {
          queryLoading.value = false
          throw error
        }
      }
    }

    const handleResetForm = () => {
      console.log('reset')
      formData.value = {}
      currentPage = 1
      handleSearch({})
    }

    const handleSearchBtnClick = () => {
      console.log('search')
      handleSearch(formData.value)
    }

    const handleTabChange = () => {
      handleResetForm()
    }

    const handleGetKnowledgeInfo = async id => {
      let searchFunc = null
      if (currentTabKey.value === 'official') {
        searchFunc = service.getOfficialKnowledgePoint
      } else if (currentTabKey.value === 'teacher') {
        searchFunc = service.getTeacherKnowledgePoint
      }
      return await searchFunc(id)
    }

    // 选择完知识点
    const handleAddKnowledgePoint = async (record) => {
      try {
        queryLoading.value = true
        const info = await handleGetKnowledgeInfo(record.id)
        if (info.content && info.content.length > 0) {
          emit('onOk', { source: currentTabKey.value, content: info.content })
          emit('onCancel')
        } else {
          message.error('该知识点内容为空,请重新选择知识点')
        }
      } finally {
        queryLoading.value = false
      }
    }

    const handelCancel = () => {
      emit('onCancel')
    }

    onMounted(async () => {
      handleSearch().then(() => {
        isFirst.value = true
      })
    })

    return {
      visibleCompu,
      tabKeys,
      list,
      currentTabKey,
      formData,
      columns,
      pagination,
      tabKeyName,
      queryLoading,
      handleAddKnowledgePoint,
      handelCancel,
      handleResetForm,
      handleSearchBtnClick,
      handleTabChange,
      locale
    }
  }
}
</script>

<style>
</style>
